.invalid-email {
  border: 2px solid crimson !important;
}

.invalid-email:focus-within {
  border: 2px solid crimson !important;
}

.red-text {
  color: red;
}
