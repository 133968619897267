.settings-header {
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.settings-body {
  height: fit-content;
  padding-bottom: 2%;
  background-color: #f3fbff;
}

.feedback-button {
  width: fit-content;
  padding: 1%;
}

.settings-header-text {
  font-size: large;
  font-weight: bold;
}

.settings-watch-header {
  font-size: x-large;
  font-weight: bold;
}

.settings-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}

.settings-sub-container {
  width: 45%;
  height: fit-content;
}

.settings-headers-with-switch {
  display: flex;
  justify-content: space-between;
  font-size: medium;
  font-weight: bold;
  margin-top: 1%;
}

.settings-desc {
  width: 70%;
  margin-top: 1%;
}

.settings-item {
  padding: 2%;
  border-bottom: 1px solid lightgrey;
}

.auto-measure-interval {
  width: 98%;
}

.settings-link-button {
  color: #1a74d4;
  text-decoration: underline;
  font-weight: 700;
}

.settings-body-container {
  width: 90%;
}

.settings-right-item {
  padding: 2%;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  cursor: pointer;
}

.about-item {
  padding: 2%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
}

.about-label {
  font-weight: 600;
}

.vital-parameter-item {
  padding: 2%;
  /* margin: 2%; */
  border: 1px solid #e2e3e6;
  border-radius: 1vh;
  background-color: white;
}

.vital-header {
  margin: 1%;
  margin-bottom: 0;
  font-size: x-large;
  font-weight: bold;
}

.vital-label {
  margin-left: 1%;
  font-size: small;
}

.vital-switch {
  width: 100%;
}

.vital-param-bar-container {
  padding: 2%;
  border-bottom: 1px solid lightgrey;
}

.vital-param-bar {
  width: 100%;
  height: 1vh;
  border: 1px solid;
}

.vital-bar-container {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
}

.vital-param-bar-item {
  display: flex;
  flex-direction: column;
  margin-right: 1%;
}

.vital-param-first-item {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.vital-param-last-item {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
