.delete-text-blue {
  color: #1a74d4;
  margin-left: 1%;
  margin-top: 1%;
  text-decoration: underline;
  cursor: pointer;
}

.contact-delete-container {
  display: flex;
}

.edit-info {
  font-size: 25px;
  font-weight: 700;
  margin-top: 10px;
}
