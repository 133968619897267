.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 30%;
  max-width: 30%;
  border-radius: 5px;
  text-align: center;
  height: 450px;
  margin: 20px 10%;
  background-color: white;
}

.card:hover,
.sub-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sub-card-header {
  text-align: center;
  border-bottom: 1px dashed #585858;
}

.sub-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 60%;
  max-width: 60%;
  border-radius: 30px;
  /* text-align: center; */
  /* height: 450px; */
  margin: 20px 15%;
  background-color: #f9fafb;
}

.sub-card-body {
  text-align: left;
  padding: 25px;
  margin-left: 100px;
}

.sub-card-body td {
  padding-left: 100px;
}

.container {
  height: 95%;
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
}

.top-banner {
  flex: 90%;
}

.price-tag {
  font-size: 20px;
  color: blueviolet;
}

.btn {
  /* background-color: #4CAF50; Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary {
  background-color: #008cba;
}

.btnDiv {
  flex: 10%;
}

.heading-color {
  color: #1e90ff;
}

.parent {
  display: flex;
  flex-wrap: wrap;
  background-color: #f3fbff;
}

.child {
  flex: 30%; /* explanation below */
}

.loader-pos {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
}
