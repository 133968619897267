.terms-of-service-body {
  margin-top: 3%;
  padding-bottom: 12vh;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
  background-color: white;
  border-top: 1px solid lightgrey;
}

.terms-button-group {
  display: flex;
  width: 50%;
  float: right;
  margin-top: 2vh;
}

.terms-button-group-mobile {
  display: flex;
  margin-top: 2vh;
  justify-content: space-around;
  width: 100%;
}

.terms-of-service-body {
  width: 80%;
}

.agree-button {
  width: 40% !important;
  margin-left: 2%;
}

.terms-container {
  overflow-y: scroll;
}

.terms-container .dash-body-container {
  height: 90vh;
}
