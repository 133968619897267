.bar-label-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-left: 7%;
  margin-top: 0;
}

svg line {
  fill: none;
  stroke: #000;
}

.chart-container {
  display: flex;
  width: 100%;
}

.line-labels {
  width: 5%;
}

.tooltip {
  padding: 1%;
  position: absolute;
  margin-top: 20vh;
  margin-left: 25%;
  background-color: white;
  border: 1px solid rgb(168, 157, 157);
  border-radius: 1vh;
  text-align: center;
}

.bar-pointer {
  padding: 4px;
  border: 1px solid black;
}

.tooltip-value {
  font-weight: bold;
  font-size: large;
}

.compare-standard-container {
  margin-right: 2%;
  display: flex;
  justify-content: flex-end;
}

.three-dashed-line {
  width: 16px;
  margin-top: 9px;
  margin-left: 2px;
  margin-right: 2px;
  border-top: 1px dashed grey;
}

.graph-line {
  width: 18px;
  height: 1px;
  margin-top: 9px;
  margin-left: 2%;
  /* margin-right: 1%; */
  border: 1px solid;
}
