.personal-sub-text {
  margin-top: 2%;
  margin-bottom: 5%;
}

.height-sub-textfield {
  width: 45% !important;
}

.dob-sub-textfield {
  width: 32%;
}
.multi-textbox-conatiner {
  margin-top: 4%;
}

.primary-button_continue button {
  width: 80%;
}

.dob-sub-textfield-personal-info {
  margin-right: 19%;
}

.multi-textboxes {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.multi-dropdown-item {
  width: 49%;
}

.multi-textboxes-profile {
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
  margin-top: 2%;
  width: 100%;
}

.multi-dropdown-item-profile {
  width: 50%;
}
.multi-dropdown-item-profile:first-child {
  padding-right: 10px;
}
.multi-dropdown-item-profile:last-child {
  padding-left: 10px;
}

.multi-dropdown-item-profile select {
  width: 100%;
}

.dob-container {
  display: flex;
  flex-direction: column;
}

.dob-errors-container {
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
  margin-top: 1%;
}

.dob-error-field {
  width: 90%;
}
