.profile-name {
  font-weight: 600;
  margin-left: 1%;
  font-size: large;
}

/* .card-overflow {
    max-height: 70vh;
    overflow: auto;
} */

.contact-box-container {
  border: 1px solid lightgrey;
  padding: 1%;
  margin-top: 2%;
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 1vh;
}

.contact-box-left-container {
  width: 10%;
  border-right: 1px solid lightgray;
  padding: 1%;
}

.contact-box-right-container {
  width: 90%;
  margin-left: 4%;
  display: flex;
  justify-content: row;
}

.profile-data {
  width: 90%;
  margin: 2%;
}

.share-data-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  margin-top: 1%;
}

.share-data-sub-container {
  width: 50%;
  padding: 1%;
}

.share-data-body-container {
  width: 90%;
  margin-left: 5%;
}

.w-90 {
  width: 90%;
}

.share-data-heading {
  font-weight: 700;
  font-size: x-large;
}

.radio-buttons {
  margin: 2%;
  margin-left: 0;
}

.share-data-label {
  font-weight: bold;
  margin: 1%;
  margin-left: 0;
}

.share-data-button {
  width: 12% !important;
  padding: 1% !important;
  border-radius: 1vh !important;
}

.share-data-button-container {
  text-align: end;
  margin-top: 2%;
}

.share-data-top-header-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
}

.share-data-top-header-text {
  font-size: large;
  font-weight: bold;
}

.share-data-body {
  padding-bottom: 2%;
  background-color: #f3fbff;
}

.link-button {
  color: #1a74d4;
  cursor: pointer;
  margin-top: 1%;
}

.success-snackbar {
  position: absolute;
  bottom: 0;
  width: 80%;
  z-index: 1;
}

.share-data-success-message {
  width: 60%;
}
