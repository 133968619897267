.success-message {
  color: green;
  margin-top: 2%;
  padding: 1%;
  margin-bottom: 2%;
  border: 1px solid;
  background-color: #e8fbdb;
  position: fixed;
  opacity: 0.8;
  bottom: 0;
  left: 10px;
  width: 50%;
  z-index: 1;
  border-radius: 1vh;
}
