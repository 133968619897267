.pre-authentication-container {
  display: flex;
  margin-top: 2vh;
}

.pre-authentication-image-container {
  width: 50%;
  text-align: center;
  margin-top: 4%;
}

.pre-authentication-body-container {
  width: 50%;
}

.pre-authentication-header {
  font-weight: 700;
  font-size: xx-large;
}

.pre-authentication-textbox-container {
  margin-top: 4%;
}

.pre-authentication-forget-password {
  margin-top: 2%;
  font-variant: underline;
  color: dodgerblue;
  font-size: small;
  text-align: right;
  margin-right: 0;
}

.pre-authentication-button-container {
  margin-top: 7%;
}

.signup-switch-container {
  margin-top: 2%;
}

.signup-link {
  color: dodgerblue;
  cursor: pointer;
  font-weight: 600;
}

.pre-authentication-disable-div {
  pointer-events: none;
  opacity: 0.5;
}
