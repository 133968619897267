.text-box-container {
  width: 100%;
  padding: 2%;
  margin-top: 1%;
  border: 1px solid grey;
  box-sizing: border-box;
}

.text-box-container:focus-within {
  border: 2px solid lightblue;
  outline: none;
}

.plain-box-conatiner {
  display: flex;
  flex-direction: column;
}

.invalid-message {
  font-size: smaller;
  color: crimson;
  width: 30%;
  padding: 1%;
}
