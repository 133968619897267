.dropdown-container {
  width: 80%;
  padding: 2%;
}

.loader {
  background-color: whitesmoke;
  color: grey;
}

.error-text {
  background-color: lightpink;
  color: crimson;
  border: 1px solid;
}

.font-rajdhani {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  font-size: medium;
}

.hide-select-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
}
