.password-field {
  width: 97%;
  border: none;
}

.password-field:focus {
  outline: none;
}

.password-text-box-container {
  box-sizing: border-box;
  display: flex;
  margin-top: 1%;
  padding: 2%;
  border: 1px solid grey;
  width: 100%;
}

.password-text-box-container:focus-within {
  border: 2px solid lightblue;
}
