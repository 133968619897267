.dash-header-title {
  font-weight: bold;
  font-size: larger;
  padding-top: 1%;
  padding-left: 10%;
  padding-right: 10%;
  width: 90%;
  background-color: white;
  position: fixed;
}

/* This makes the content asymetric, to make content centric, add class 'dash-body-centered',*/
.dash-body {
  padding-top: 10vh;
  padding-left: 10%;
  flex: 1;
}

.dash-body-centered {
  padding-right: 10%;
}

.footer-text {
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: small;
  width: 50%;
}

.align-last {
  text-align: end;
}

.footer-container {
  display: flex;
  padding-left: 10%;
  background-color: #3b5876;
  padding-right: 10%;
}

.dash-footer {
  bottom: 0vh;
  position: relative;
  width: 100%;
}

.space-footer-text {
  margin-left: 2%;
  margin-right: 2%;
}

.header-icons-container {
  width: 60%;
}

.header-icons-container-multiple {
  text-align: end;
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.header-icons-active {
  border-bottom: 2px solid dodgerblue;
}

.header-container {
  display: flex;
}

.divider {
  width: 1px;
  height: 20px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: #d9d9d9;
}

.profile-info {
  margin-top: 4%;
  font-weight: 600;
}

.post-authenticate-body {
  background-color: #f3fbff;
}

.profile-container {
  margin-bottom: 1%;
}

.arrow-below {
  margin-left: 1vh;
  margin-top: 1.5vh;
  /* cursor: pointer; */
}

.home-header-icon {
  cursor: pointer;
  margin-left: 2%;
  margin-right: 2%;
}

.dash-body-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.profile-dropdown-container {
  margin-left: 67vw;
  top: 5vw;
  width: 15%;
  box-shadow: 0 0 8px 4px #cbccd2;
  background-color: white;
  position: fixed;
  text-align: center;
  border: 1px solid #cbccd2;
  border-radius: 1vh;
}

.profile-dropdown-item {
  padding-top: 5%;
  padding-bottom: 5%;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
}

.profile-dropdown-item:hover {
  font-size: large;
  text-shadow: 1px 1px #cbccd2;
}

.profile-icon-container {
  display: flex;
  cursor: pointer;
}
