.drawer-body {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  padding-top: 0;
  height: fit-content;
  background-color: inherit;
}

.meal-container {
  width: 85%;
  padding-top: 10vh;
}

.meal-header {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.add-meal-button {
  width: 12%;
  padding: 1%;
}

.meal-icon {
  margin-left: 10%;
  margin-right: 4%;
}

.meal-header-label {
  display: flex;
  flex-direction: row;
}

.meal-header-item {
  display: flex;
  justify-content: space-between;
}

.meal-underline {
  color: #1a74d4;
  text-decoration: underline;
}

.meal-calendar-icon {
  margin-top: 2%;
}

input[type='date']:required:invalid::-webkit-datetime-edit {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0;
  background-color: #fff !important;
  color: #000 !important;
}

.no-meal-found {
  font-weight: bold;
  font-size: x-large;
  margin: 3%;
}

.textarea-label {
  font-weight: bold;
  margin: 1%;
}

.add-meal-radio {
  margin: 2%;
  display: flex;
  flex-direction: row;
  font-weight: bolder;
}

.add-meal-textbox {
  width: 95%;
}

.meal-list-container {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  border-bottom: 1px solid lightgrey;
}

.meal-list-body {
  margin-top: 1%;
  width: 80%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.meal-text-box {
  padding: 2%;
  margin-left: 9%;
  width: 87%;
  border-radius: 4px;
  border: 1px solid #ced4da;
  pointer-events: none;
}

.meal-text-box:focus {
  border-radius: 4px;
  background-color: white;
  border-color: #80bdff;
  border: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.meal-dropdown {
  width: 80%;
  border: 1px solid grey;
  border-bottom: 0;
  padding: 1%;
  margin-left: 10%;
  background-color: white;
}

.meal-dropdown:focus {
  border-radius: 4;
  border-color: #80bdff;
  background-color: inherit;
  border-bottom: 0 !important;
  border: 0;
  /* box-shadow: '0 0 0 0.2rem rgba(0,123,255,.25)', */
}

.meal-dropdown-item {
  background-color: white;
  display: flex;
}

.meal-loader {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.edit-meal {
  color: #1a74d4;
  margin: 15%;
}
