.back-button {
  margin-bottom: 3%;
  cursor: pointer;
  display: flex;
  width: fit-content;
}

.signup-button-container {
  margin-top: 5%;
  margin-bottom: 1vh;
}

.invalid-password {
  color: red;
  width: 80%;
}
