.add-feedback-header-text {
  font-size: x-large;
  font-weight: bold;
  margin-top: 3%;
}

.add-feedback-message-box {
  margin-top: 1%;
  width: 98%;
}

.add-feedback-button {
  width: 97%;
}
