.primary-button {
  text-align: center;
  padding: 2%;
  background-color: #1a74d4;
  border: none;
  color: white;
  width: 100%;
  cursor: pointer;
  border-radius: 1vh;
  font-weight: 600;
  text-transform: uppercase;
}

.primary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primary-button:active {
  outline: none;
}

.primary-button:focus {
  outline: none;
}
