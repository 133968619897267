.alert-stop-text {
  font-size: x-large;
  text-align: center;
  font-weight: bold;
}

.alert-stop-container {
  width: 35%;
  padding: 1%;
  border: 1px solid lightgrey;
  border-radius: 1vh;
  background-color: white;
  /* overflow: visible !important; */
}

.alert-stop-button-group {
  margin-top: 5%;
  display: flex;
  justify-content: space-around;
}

.alert-stop-button {
  width: 40%;
}
