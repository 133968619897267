.left-side-drawer {
  width: 25%;
  padding-top: 12vh;
  background-color: white;
  border-right: 1px solid lightgrey;
}

.drawer-children {
  width: 80%;
  padding-bottom: 2%;
}

.drawer-item {
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  color: #757575;
  margin-top: 2%;
  padding-bottom: 2%;
  padding-top: 2%;
}

.drawer-item-text {
  margin-left: 5%;
}

.drawer-item-active {
  font-weight: 600;
  color: black;
  border-right: 2px solid #1a74d4;
  background-image: linear-gradient(to right, #ffffff, #f3fbff);
}

.drawer-dashboard {
  color: #1a74d4;
  border-bottom: 1px solid #e2e3e6;
}
