.health-header-label {
  display: flex;
  width: 30%;
}

.add-health-button {
  width: 16%;
  padding: 1%;
}

.add-health-header-text {
  font-weight: bold;
}

.symptom-dropdown {
  width: 95%;
}

.health-dropdown-box {
  width: 87%;
}
