.modal {
  background-color: rgba(243, 251, 255, 0.9);
  min-height: 100vh;
  width: 100%;
  align-items: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  position: fixed;
  overflow: hidden;
}
