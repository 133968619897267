.add-contact-container {
  width: 50%;
  padding: 1%;
  border: 1px solid lightgrey;
  border-radius: 1vh;
  background-color: white;
  /* overflow: visible !important; */
}

.add-contact-header {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  border-bottom: 1px solid lightgray;
}

.add-contact-header-text {
  font-size: large;
  font-weight: bold;
}

.add-contact-text-box {
  margin-top: 2%;
}

.add-contact-text-box-input {
  width: 95%;
}

.add-contact-downdown {
  width: 96%;
}
