.profile-header {
  font-size: large;
  font-weight: bold;
}

.sub-page {
  text-align: left;
  margin-left: 15%;
}

.sub-page td {
  padding-left: 100px;
}

.profile-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.profile-sub-container {
  width: 43%;
  margin-top: 1%;
  padding: 0;
  height: fit-content;
}

.profile-add-picture {
  padding: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid lightgrey;
}

.profile-item-container {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  font-weight: 600;
  cursor: pointer;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.profile-name-text {
  font-size: x-large;
  font-weight: bold;
  margin-top: 2%;
}

.lower-item-info-container {
  margin: 2%;
}

.lower-item-error-text-box {
  width: 95%;
  border-color: red;
}

.lower-item-error-text-box-profile {
  border-color: red;
}

.profile-body {
  height: fit-content;
  padding-bottom: 7%;
  background-color: #f3fbff;
}

.account-info-button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 2%;
}

.account-info-button {
  width: 45%;
  border-radius: 1vh;
}

.known-disease-loader {
  padding: 2%;
  color: grey;
  border: 1px solid;
  width: 95%;
  background-color: whitesmoke;
}

.profile-other-disease-text-box {
  margin-left: 10%;
}

.profile-add-contact-header {
  font-size: x-large;
  font-weight: bold;
  margin-top: 1%;
}

.profile-add-contact-container {
  padding: 2%;
}

.profile-contact-sub-container {
  width: 90%;
  margin-left: 1%;
  display: flex;
}

.profile-contact-data {
  width: 90%;
}

.profile-edit-icon {
  cursor: pointer;
}

.profile-image {
  width: 80px;
  height: 76px;
  border-radius: 50%;
  border: 1px solid #1a74d4;
}

.profile-plus-icon {
  color: #1a74d4;
  margin-left: -4vh;
  padding: 1%;
  font-size: 1.6em;
  border-radius: 50%;
  background-color: white;
}

.no-contact {
  padding: 2%;
  background-color: ghostwhite;
  font-weight: 700;
}

.profile-loader {
  display: flex;
  justify-content: center;
  padding: 3%;
}

.profile-daily-step {
  padding: 2%;
}

.step-goal-dropdown {
  width: 98%;
}

.change-step-goal {
  width: 96%;
  padding: 1%;
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  color: gray;
  background-color: whitesmoke;
  border: 1px solid lightgrey;
  border-radius: 1vh;
}

.medical-info-error {
  margin: 1%;
  width: 90%;
  margin-left: 3%;
}

.personal-info-error {
  width: 85%;
  margin: 1%;
  margin-left: 3%;
}

.profile-pic-upload {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}
