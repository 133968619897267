.card-container {
  width: 98%;
  margin-left: 2%;
  margin-top: 1%;
  background-color: #fafafa;
  border: 1px solid #e2e3e6;
  border-radius: 1vh;
}

.card-header {
  padding: 2%;
  border-top-left-radius: 1vh;
  border-top-right-radius: 1vh;
  border-bottom: 1px solid #e2e3e6;
  background-color: white;
}

.card-content {
  height: 65vh;
  display: block;
  overflow: auto;
}
