.radio-button {
  margin-right: 1%;
}

.radio-label {
  font-weight: 600;
}

.radio-button-container {
  margin-top: 1%;
}
