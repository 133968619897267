.fixed-placeholder-field {
  width: 97%;
  border: none;
}

.fixed-placeholder-field:focus {
  outline: none;
}

.fixed-placeholder-text-box-container {
  display: flex;
  margin-top: 2%;
  padding: 2%;
  border: 1px solid grey;
}

.fixed-placeholder-text-box-container:focus-within {
  border: 2px solid lightblue;
}

.fixed-placeholder-text {
  color: gray;
  font-size: small;
  margin-top: 1%;
}
