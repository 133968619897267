.error-message {
  color: crimson !important;
  margin-top: 2%;
  padding: 2%;
  margin-bottom: 2%;
  width: 81%;
  border: 1px solid;
  background-color: lightpink !important;
  opacity: 0.7;
}
