@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-underline {
  text-decoration: none;
  color: inherit;
}

input,
select,
textarea {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  font-size: medium;
}

.fa {
  cursor: pointer;
}

.mt-1 {
  margin-top: 1%;
}

.mt-2 {
  margin-top: 2%;
}

.d-flex {
  display: flex;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.c-pointer {
  cursor: pointer;
}

.ml-1 {
  margin-left: 1% !important;
}

.m-1 {
  margin: 1%;
}

.m-2 {
  margin: 2%;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.w-100 {
  width: 100% !important;
}

.text-bold {
  font-weight: bold;
}

.w-5 {
  width: 5%;
}

.mlr-1 {
  margin-left: 1%;
  margin-right: 1%;
}

.thick-black-divider {
  height: 2px !important;
  background-color: black !important;
}

.w-30 {
  width: 30%;
}

.ml-3 {
  margin-left: 3% !important;
}

.ml-2 {
  margin-left: 2% !important;
}

.no-pointer {
  pointer-events: none;
}

.primary-button {
  text-align: center;
  padding: 2%;
  background-color: #1a74d4;
  border: none;
  color: white;
  width: 100%;
  cursor: pointer;
  border-radius: 1vh;
  font-weight: 600;
  text-transform: uppercase;
}

.primary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primary-button:active {
  outline: none;
}

.primary-button:focus {
  outline: none;
}

.error-message {
  color: crimson !important;
  margin-top: 2%;
  padding: 2%;
  margin-bottom: 2%;
  width: 81%;
  border: 1px solid;
  background-color: lightpink !important;
  opacity: 0.7;
}

.dash-header-title {
  font-weight: bold;
  font-size: larger;
  padding-top: 1%;
  padding-left: 10%;
  padding-right: 10%;
  width: 90%;
  background-color: white;
  position: fixed;
}

/* This makes the content asymetric, to make content centric, add class 'dash-body-centered',*/
.dash-body {
  padding-top: 10vh;
  padding-left: 10%;
  flex: 1 1;
}

.dash-body-centered {
  padding-right: 10%;
}

.footer-text {
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: small;
  width: 50%;
}

.align-last {
  text-align: end;
}

.footer-container {
  display: flex;
  padding-left: 10%;
  background-color: #3b5876;
  padding-right: 10%;
}

.dash-footer {
  bottom: 0vh;
  position: relative;
  width: 100%;
}

.space-footer-text {
  margin-left: 2%;
  margin-right: 2%;
}

.header-icons-container {
  width: 60%;
}

.header-icons-container-multiple {
  text-align: end;
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.header-icons-active {
  border-bottom: 2px solid dodgerblue;
}

.header-container {
  display: flex;
}

.divider {
  width: 1px;
  height: 20px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: #d9d9d9;
}

.profile-info {
  margin-top: 4%;
  font-weight: 600;
}

.post-authenticate-body {
  background-color: #f3fbff;
}

.profile-container {
  margin-bottom: 1%;
}

.arrow-below {
  margin-left: 1vh;
  margin-top: 1.5vh;
  /* cursor: pointer; */
}

.home-header-icon {
  cursor: pointer;
  margin-left: 2%;
  margin-right: 2%;
}

.dash-body-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.profile-dropdown-container {
  margin-left: 67vw;
  top: 5vw;
  width: 15%;
  box-shadow: 0 0 8px 4px #cbccd2;
  background-color: white;
  position: fixed;
  text-align: center;
  border: 1px solid #cbccd2;
  border-radius: 1vh;
}

.profile-dropdown-item {
  padding-top: 5%;
  padding-bottom: 5%;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
}

.profile-dropdown-item:hover {
  font-size: large;
  text-shadow: 1px 1px #cbccd2;
}

.profile-icon-container {
  display: flex;
  cursor: pointer;
}

.pre-authentication-container {
  display: flex;
  margin-top: 2vh;
}

.pre-authentication-image-container {
  width: 50%;
  text-align: center;
  margin-top: 4%;
}

.pre-authentication-body-container {
  width: 50%;
}

.pre-authentication-header {
  font-weight: 700;
  font-size: xx-large;
}

.pre-authentication-textbox-container {
  margin-top: 4%;
}

.pre-authentication-forget-password {
  margin-top: 2%;
  -webkit-font-feature-settings: ;
          font-feature-settings: ;
  font-variant: underline;
  color: dodgerblue;
  font-size: small;
  text-align: right;
  margin-right: 0;
}

.pre-authentication-button-container {
  margin-top: 7%;
}

.signup-switch-container {
  margin-top: 2%;
}

.signup-link {
  color: dodgerblue;
  cursor: pointer;
  font-weight: 600;
}

.pre-authentication-disable-div {
  pointer-events: none;
  opacity: 0.5;
}

.success-message {
  color: green;
  margin-top: 2%;
  padding: 1%;
  margin-bottom: 2%;
  border: 1px solid;
  background-color: #e8fbdb;
  position: fixed;
  opacity: 0.8;
  bottom: 0;
  left: 10px;
  width: 50%;
  z-index: 1;
  border-radius: 1vh;
}

.invalid-email {
  border: 2px solid crimson !important;
}

.invalid-email:focus-within {
  border: 2px solid crimson !important;
}

.red-text {
  color: red;
}

.forget-text {
  margin-top: 2%;
  margin-bottom: 5%;
}

.forget-password-submit-container {
  margin-top: 30%;
}

.log-out {
  margin-top: 5%;
  color: dodgerblue;
  cursor: pointer;
}

.overview-title {
  font-size: x-large;
  font-weight: 600;
  margin-top: 1%;
}

.home-header-container {
  display: flex;
  justify-content: space-between;
}

.overview-button {
  width: 12%;
  padding: 1%;
  border-radius: 1vh;
}

.overview-card {
  background-color: white;
  padding: 1%;
  width: 25%;
  border: 1px solid #e2e3e6;
  border-radius: 1vh;
}

.overview-card-details {
  margin-top: 1%;
  padding-right: 10%;
}

.details-value {
  font-size: xx-large;
  font-weight: bolder;
}

.overview-card-label-container {
  display: flex;
  justify-content: space-between;
}

.overview-card-label-value {
  font-size: x-large;
  -webkit-font-feature-settings: "pcap", "c2pc";
          font-feature-settings: "pcap", "c2pc";
  font-variant: all-petite-caps;
}

.overview-card-container {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
}

.home-body {
  width: 90%;
}

.overview-card-image-container {
  margin-top: 2%;
  padding: 4%;
  width: 35%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1vh;
}

.overview-card-image-label {
  margin-top: 15%;
  font-size: x-large;
  font-weight: 600;
}

.overview-card-images {
  display: flex;
  justify-content: space-between;
}

.card-hover:hover {
  box-shadow: 2px 2px 7px 0px lightblue;
}

.dropdown-container {
  width: 80%;
  padding: 2%;
}

.loader {
  background-color: whitesmoke;
  color: grey;
}

.error-text {
  background-color: lightpink;
  color: crimson;
  border: 1px solid;
}

.font-rajdhani {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  font-size: medium;
}

.hide-select-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.searchable-dropdown {
  padding: 0;
  margin-top: 1%;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0.5% !important;
  font-size: medium;
  font-family: inherit;
  font-weight: 500;
  border-radius: 1%;
}

.MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: lightblue !important;
}

.text-box-container {
  width: 100%;
  padding: 2%;
  margin-top: 1%;
  border: 1px solid grey;
  box-sizing: border-box;
}

.text-box-container:focus-within {
  border: 2px solid lightblue;
  outline: none;
}

.plain-box-conatiner {
  display: flex;
  flex-direction: column;
}

.invalid-message {
  font-size: smaller;
  color: crimson;
  width: 30%;
  padding: 1%;
}

.personal-sub-text {
  margin-top: 2%;
  margin-bottom: 5%;
}

.height-sub-textfield {
  width: 45% !important;
}

.dob-sub-textfield {
  width: 32%;
}
.multi-textbox-conatiner {
  margin-top: 4%;
}

.primary-button_continue button {
  width: 80%;
}

.dob-sub-textfield-personal-info {
  margin-right: 19%;
}

.multi-textboxes {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.multi-dropdown-item {
  width: 49%;
}

.multi-textboxes-profile {
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
  margin-top: 2%;
  width: 100%;
}

.multi-dropdown-item-profile {
  width: 50%;
}
.multi-dropdown-item-profile:first-child {
  padding-right: 10px;
}
.multi-dropdown-item-profile:last-child {
  padding-left: 10px;
}

.multi-dropdown-item-profile select {
  width: 100%;
}

.dob-container {
  display: flex;
  flex-direction: column;
}

.dob-errors-container {
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
  margin-top: 1%;
}

.dob-error-field {
  width: 90%;
}

.switch-container {
  display: flex;
  margin-top: 2%;
  background-color: #eeeff4;
}

.switch {
  width: 50%;
  padding: 1%;
  text-align: center;
  background-color: #eeeff4;
  cursor: pointer;
}

.switch-active {
  cursor: default;
  border: 1px solid #cbccd2;
  background-color: white;
  box-shadow: 0 0 4px 1px #cbccd2;
}

.fixed-placeholder-field {
  width: 97%;
  border: none;
}

.fixed-placeholder-field:focus {
  outline: none;
}

.fixed-placeholder-text-box-container {
  display: flex;
  margin-top: 2%;
  padding: 2%;
  border: 1px solid grey;
}

.fixed-placeholder-text-box-container:focus-within {
  border: 2px solid lightblue;
}

.fixed-placeholder-text {
  color: gray;
  font-size: small;
  margin-top: 1%;
}

.outline-primary-button {
  padding: 2%;
  width: 40%;
  border: 1px solid dodgerblue;
  color: dodgerblue;
  background-color: inherit;
  border-radius: 1vh;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
}

.outline-primary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.outline-primary-button:active {
  outline: none;
}

.outline-primary-button:focus {
  outline: none;
}

.add-contact-container {
  width: 50%;
  padding: 1%;
  border: 1px solid lightgrey;
  border-radius: 1vh;
  background-color: white;
  /* overflow: visible !important; */
}

.add-contact-header {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  border-bottom: 1px solid lightgray;
}

.add-contact-header-text {
  font-size: large;
  font-weight: bold;
}

.add-contact-text-box {
  margin-top: 2%;
}

.add-contact-text-box-input {
  width: 95%;
}

.add-contact-downdown {
  width: 96%;
}

.text-area {
  border: 1px solid lightgrey;
  padding: 2%;
  border-radius: 1vw;
  width: 98%;
}

.add-feedback-header-text {
  font-size: x-large;
  font-weight: bold;
  margin-top: 3%;
}

.add-feedback-message-box {
  margin-top: 1%;
  width: 98%;
}

.add-feedback-button {
  width: 97%;
}

.alert-stop-text {
  font-size: x-large;
  text-align: center;
  font-weight: bold;
}

.alert-stop-container {
  width: 35%;
  padding: 1%;
  border: 1px solid lightgrey;
  border-radius: 1vh;
  background-color: white;
  /* overflow: visible !important; */
}

.alert-stop-button-group {
  margin-top: 5%;
  display: flex;
  justify-content: space-around;
}

.alert-stop-button {
  width: 40%;
}

.delete-text-blue {
  color: #1a74d4;
  margin-left: 1%;
  margin-top: 1%;
  text-decoration: underline;
  cursor: pointer;
}

.contact-delete-container {
  display: flex;
}

.edit-info {
  font-size: 25px;
  font-weight: 700;
  margin-top: 10px;
}

.modal {
  background-color: rgba(243, 251, 255, 0.9);
  min-height: 100vh;
  width: 100%;
  align-items: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  position: fixed;
  overflow: hidden;
}

.radio-button {
  margin-right: 1%;
}

.radio-label {
  font-weight: 600;
}

.radio-button-container {
  margin-top: 1%;
}

.profile-name {
  font-weight: 600;
  margin-left: 1%;
  font-size: large;
}

/* .card-overflow {
    max-height: 70vh;
    overflow: auto;
} */

.contact-box-container {
  border: 1px solid lightgrey;
  padding: 1%;
  margin-top: 2%;
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 1vh;
}

.contact-box-left-container {
  width: 10%;
  border-right: 1px solid lightgray;
  padding: 1%;
}

.contact-box-right-container {
  width: 90%;
  margin-left: 4%;
  display: flex;
  justify-content: row;
}

.profile-data {
  width: 90%;
  margin: 2%;
}

.share-data-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  margin-top: 1%;
}

.share-data-sub-container {
  width: 50%;
  padding: 1%;
}

.share-data-body-container {
  width: 90%;
  margin-left: 5%;
}

.w-90 {
  width: 90%;
}

.share-data-heading {
  font-weight: 700;
  font-size: x-large;
}

.radio-buttons {
  margin: 2%;
  margin-left: 0;
}

.share-data-label {
  font-weight: bold;
  margin: 1%;
  margin-left: 0;
}

.share-data-button {
  width: 12% !important;
  padding: 1% !important;
  border-radius: 1vh !important;
}

.share-data-button-container {
  text-align: end;
  margin-top: 2%;
}

.share-data-top-header-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
}

.share-data-top-header-text {
  font-size: large;
  font-weight: bold;
}

.share-data-body {
  padding-bottom: 2%;
  background-color: #f3fbff;
}

.link-button {
  color: #1a74d4;
  cursor: pointer;
  margin-top: 1%;
}

.success-snackbar {
  position: absolute;
  bottom: 0;
  width: 80%;
  z-index: 1;
}

.share-data-success-message {
  width: 60%;
}


.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 30%;
  max-width: 30%;
  border-radius: 5px;
  text-align: center;
  height: 450px;
  margin: 20px 10%;
  background-color: white;
}

.card:hover,
.sub-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sub-card-header {
  text-align: center;
  border-bottom: 1px dashed #585858;
}

.sub-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 60%;
  max-width: 60%;
  border-radius: 30px;
  /* text-align: center; */
  /* height: 450px; */
  margin: 20px 15%;
  background-color: #f9fafb;
}

.sub-card-body {
  text-align: left;
  padding: 25px;
  margin-left: 100px;
}

.sub-card-body td {
  padding-left: 100px;
}

.container {
  height: 95%;
  padding: 2px 16px;
  display: flex;
  flex-direction: column;
}

.top-banner {
  flex: 90% 1;
}

.price-tag {
  font-size: 20px;
  color: blueviolet;
}

.btn {
  /* background-color: #4CAF50; Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary {
  background-color: #008cba;
}

.btnDiv {
  flex: 10% 1;
}

.heading-color {
  color: #1e90ff;
}

.parent {
  display: flex;
  flex-wrap: wrap;
  background-color: #f3fbff;
}

.child {
  flex: 30% 1; /* explanation below */
}

.loader-pos {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
}

.password-field {
  width: 97%;
  border: none;
}

.password-field:focus {
  outline: none;
}

.password-text-box-container {
  box-sizing: border-box;
  display: flex;
  margin-top: 1%;
  padding: 2%;
  border: 1px solid grey;
  width: 100%;
}

.password-text-box-container:focus-within {
  border: 2px solid lightblue;
}

.back-button {
  margin-bottom: 3%;
  cursor: pointer;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.signup-button-container {
  margin-top: 5%;
  margin-bottom: 1vh;
}

.invalid-password {
  color: red;
  width: 80%;
}

.terms-of-service-body {
  margin-top: 3%;
  padding-bottom: 12vh;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  height: 10vh;
  width: 100%;
  background-color: white;
  border-top: 1px solid lightgrey;
}

.terms-button-group {
  display: flex;
  width: 50%;
  float: right;
  margin-top: 2vh;
}

.terms-button-group-mobile {
  display: flex;
  margin-top: 2vh;
  justify-content: space-around;
  width: 100%;
}

.terms-of-service-body {
  width: 80%;
}

.agree-button {
  width: 40% !important;
  margin-left: 2%;
}

.terms-container {
  overflow-y: scroll;
}

.terms-container .dash-body-container {
  height: 90vh;
}

.verify-text {
  margin-top: 2vh;
}

.verify-continue-container {
  margin-top: 30%;
}

.profile-header {
  font-size: large;
  font-weight: bold;
}

.sub-page {
  text-align: left;
  margin-left: 15%;
}

.sub-page td {
  padding-left: 100px;
}

.profile-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.profile-sub-container {
  width: 43%;
  margin-top: 1%;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.profile-add-picture {
  padding: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid lightgrey;
}

.profile-item-container {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  font-weight: 600;
  cursor: pointer;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.profile-name-text {
  font-size: x-large;
  font-weight: bold;
  margin-top: 2%;
}

.lower-item-info-container {
  margin: 2%;
}

.lower-item-error-text-box {
  width: 95%;
  border-color: red;
}

.lower-item-error-text-box-profile {
  border-color: red;
}

.profile-body {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 7%;
  background-color: #f3fbff;
}

.account-info-button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 2%;
}

.account-info-button {
  width: 45%;
  border-radius: 1vh;
}

.known-disease-loader {
  padding: 2%;
  color: grey;
  border: 1px solid;
  width: 95%;
  background-color: whitesmoke;
}

.profile-other-disease-text-box {
  margin-left: 10%;
}

.profile-add-contact-header {
  font-size: x-large;
  font-weight: bold;
  margin-top: 1%;
}

.profile-add-contact-container {
  padding: 2%;
}

.profile-contact-sub-container {
  width: 90%;
  margin-left: 1%;
  display: flex;
}

.profile-contact-data {
  width: 90%;
}

.profile-edit-icon {
  cursor: pointer;
}

.profile-image {
  width: 80px;
  height: 76px;
  border-radius: 50%;
  border: 1px solid #1a74d4;
}

.profile-plus-icon {
  color: #1a74d4;
  margin-left: -4vh;
  padding: 1%;
  font-size: 1.6em;
  border-radius: 50%;
  background-color: white;
}

.no-contact {
  padding: 2%;
  background-color: ghostwhite;
  font-weight: 700;
}

.profile-loader {
  display: flex;
  justify-content: center;
  padding: 3%;
}

.profile-daily-step {
  padding: 2%;
}

.step-goal-dropdown {
  width: 98%;
}

.change-step-goal {
  width: 96%;
  padding: 1%;
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  color: gray;
  background-color: whitesmoke;
  border: 1px solid lightgrey;
  border-radius: 1vh;
}

.medical-info-error {
  margin: 1%;
  width: 90%;
  margin-left: 3%;
}

.personal-info-error {
  width: 85%;
  margin: 1%;
  margin-left: 3%;
}

.profile-pic-upload {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.check-box-container {
  display: flex;
  margin-top: 1%;
}

.check-box-label {
  margin-left: 4%;
  font-weight: 600;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.settings-body {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 2%;
  background-color: #f3fbff;
}

.feedback-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1%;
}

.settings-header-text {
  font-size: large;
  font-weight: bold;
}

.settings-watch-header {
  font-size: x-large;
  font-weight: bold;
}

.settings-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}

.settings-sub-container {
  width: 45%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.settings-headers-with-switch {
  display: flex;
  justify-content: space-between;
  font-size: medium;
  font-weight: bold;
  margin-top: 1%;
}

.settings-desc {
  width: 70%;
  margin-top: 1%;
}

.settings-item {
  padding: 2%;
  border-bottom: 1px solid lightgrey;
}

.auto-measure-interval {
  width: 98%;
}

.settings-link-button {
  color: #1a74d4;
  text-decoration: underline;
  font-weight: 700;
}

.settings-body-container {
  width: 90%;
}

.settings-right-item {
  padding: 2%;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  cursor: pointer;
}

.about-item {
  padding: 2%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
}

.about-label {
  font-weight: 600;
}

.vital-parameter-item {
  padding: 2%;
  /* margin: 2%; */
  border: 1px solid #e2e3e6;
  border-radius: 1vh;
  background-color: white;
}

.vital-header {
  margin: 1%;
  margin-bottom: 0;
  font-size: x-large;
  font-weight: bold;
}

.vital-label {
  margin-left: 1%;
  font-size: small;
}

.vital-switch {
  width: 100%;
}

.vital-param-bar-container {
  padding: 2%;
  border-bottom: 1px solid lightgrey;
}

.vital-param-bar {
  width: 100%;
  height: 1vh;
  border: 1px solid;
}

.vital-bar-container {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
}

.vital-param-bar-item {
  display: flex;
  flex-direction: column;
  margin-right: 1%;
}

.vital-param-first-item {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.vital-param-last-item {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.card-container {
  width: 98%;
  margin-left: 2%;
  margin-top: 1%;
  background-color: #fafafa;
  border: 1px solid #e2e3e6;
  border-radius: 1vh;
}

.card-header {
  padding: 2%;
  border-top-left-radius: 1vh;
  border-top-right-radius: 1vh;
  border-bottom: 1px solid #e2e3e6;
  background-color: white;
}

.card-content {
  height: 65vh;
  display: block;
  overflow: auto;
}

.left-side-drawer {
  width: 25%;
  padding-top: 12vh;
  background-color: white;
  border-right: 1px solid lightgrey;
}

.drawer-children {
  width: 80%;
  padding-bottom: 2%;
}

.drawer-item {
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  color: #757575;
  margin-top: 2%;
  padding-bottom: 2%;
  padding-top: 2%;
}

.drawer-item-text {
  margin-left: 5%;
}

.drawer-item-active {
  font-weight: 600;
  color: black;
  border-right: 2px solid #1a74d4;
  background-image: linear-gradient(to right, #ffffff, #f3fbff);
}

.drawer-dashboard {
  color: #1a74d4;
  border-bottom: 1px solid #e2e3e6;
}

.drawer-body {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  padding-top: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: inherit;
}

.meal-container {
  width: 85%;
  padding-top: 10vh;
}

.meal-header {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.add-meal-button {
  width: 12%;
  padding: 1%;
}

.meal-icon {
  margin-left: 10%;
  margin-right: 4%;
}

.meal-header-label {
  display: flex;
  flex-direction: row;
}

.meal-header-item {
  display: flex;
  justify-content: space-between;
}

.meal-underline {
  color: #1a74d4;
  text-decoration: underline;
}

.meal-calendar-icon {
  margin-top: 2%;
}

input[type='date']:required:invalid::-webkit-datetime-edit {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0;
  background-color: #fff !important;
  color: #000 !important;
}

.no-meal-found {
  font-weight: bold;
  font-size: x-large;
  margin: 3%;
}

.textarea-label {
  font-weight: bold;
  margin: 1%;
}

.add-meal-radio {
  margin: 2%;
  display: flex;
  flex-direction: row;
  font-weight: bolder;
}

.add-meal-textbox {
  width: 95%;
}

.meal-list-container {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  border-bottom: 1px solid lightgrey;
}

.meal-list-body {
  margin-top: 1%;
  width: 80%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.meal-text-box {
  padding: 2%;
  margin-left: 9%;
  width: 87%;
  border-radius: 4px;
  border: 1px solid #ced4da;
  pointer-events: none;
}

.meal-text-box:focus {
  border-radius: 4px;
  background-color: white;
  border-color: #80bdff;
  border: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.meal-dropdown {
  width: 80%;
  border: 1px solid grey;
  border-bottom: 0;
  padding: 1%;
  margin-left: 10%;
  background-color: white;
}

.meal-dropdown:focus {
  border-radius: 4;
  border-color: #80bdff;
  background-color: inherit;
  border-bottom: 0 !important;
  border: 0;
  /* box-shadow: '0 0 0 0.2rem rgba(0,123,255,.25)', */
}

.meal-dropdown-item {
  background-color: white;
  display: flex;
}

.meal-loader {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.edit-meal {
  color: #1a74d4;
  margin: 15%;
}

.health-header-label {
  display: flex;
  width: 30%;
}

.add-health-button {
  width: 16%;
  padding: 1%;
}

.add-health-header-text {
  font-weight: bold;
}

.symptom-dropdown {
  width: 95%;
}

.health-dropdown-box {
  width: 87%;
}

.bar-label-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-left: 7%;
  margin-top: 0;
}

svg line {
  fill: none;
  stroke: #000;
}

.chart-container {
  display: flex;
  width: 100%;
}

.line-labels {
  width: 5%;
}

.tooltip {
  padding: 1%;
  position: absolute;
  margin-top: 20vh;
  margin-left: 25%;
  background-color: white;
  border: 1px solid rgb(168, 157, 157);
  border-radius: 1vh;
  text-align: center;
}

.bar-pointer {
  padding: 4px;
  border: 1px solid black;
}

.tooltip-value {
  font-weight: bold;
  font-size: large;
}

.compare-standard-container {
  margin-right: 2%;
  display: flex;
  justify-content: flex-end;
}

.three-dashed-line {
  width: 16px;
  margin-top: 9px;
  margin-left: 2px;
  margin-right: 2px;
  border-top: 1px dashed grey;
}

.graph-line {
  width: 18px;
  height: 1px;
  margin-top: 9px;
  margin-left: 2%;
  /* margin-right: 1%; */
  border: 1px solid;
}

.steps-header-label {
  width: 50%;
  display: flex;
}

.steps-walked-header {
  display: flex;
  width: 100%;
}

.steps-header-left {
  width: 50%;
  margin-top: 0.8%;
}

.steps-header-right {
  width: 50%;
}

.steps-switch {
  width: 100%;
  margin-top: 0;
}

.steps-header-text {
  font-weight: 600;
  margin-left: 2%;
  margin-right: 2%;
}

.steps-card-header {
  padding: 1.5%;
}

.steps-bar-header {
  display: flex;
  margin-top: 1%;
  font-weight: bold;
  align-items: center;
  border-bottom: 1px solid #e2e3e6;
}

.steps-bar-value {
  font-size: x-large;
  margin-left: 2%;
}

.steps-bar-text {
  margin-left: 3%;
}

.graph-lower-container {
  display: flex;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 0.5%;
  border-radius: 1vh;
  width: 90%;
  margin-left: 5%;
  align-items: center;
  border: 1px solid lightgrey;
  font-weight: bold;
}

.graph-lower-container-text {
  margin-left: 3%;
}

.glucose-header-icon {
  margin: 1%;
  height: 28px;
  width: 28px;
}

.blood-glucose-header-label {
  margin-top: 1%;
  margin-left: 2%;
  width: 50%;
  display: flex;
}

.blood-glucose-content-header {
  display: flex;
  justify-content: space-between;
  margin: 0.5%;
  align-items: center;
}

.blood-glucose-content-text {
  font-weight: bold;
  margin-left: 2%;
}

.blood-glucose-bound-button {
  width: -webkit-max-content;
  width: max-content;
  padding: 1%;
  margin-right: 2%;
}

.blood-glucose-time-in-container {
  margin-top: 0;
  margin-bottom: 0;
  width: 96%;
  margin-left: 2%;
}

.outer-line-blue-circle {
  border-radius: 8px;
  width: 16px;
  height: 16px;
  border: 2px solid #1a74d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contained-circle {
  border-radius: 4px;
  width: 8px;
  height: 8px;
  background-color: orange;
  border: 1px solid;
}

.dot-details-container {
  display: flex;
  width: 100%;
  padding-bottom: 1%;
}

.dot-details-left-container {
  display: flex;
  width: 30%;
  border-right: 1px solid lightgrey;
  justify-content: center;
}

.dot-details-text {
  margin-left: 3%;
}

.dot-details-right-container {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: baseline;
}

.dot-details-sub-container {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: baseline;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid;
}

.inner-triangle {
  position: relative;
  top: 1px;
  left: -9px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid;
}

.dot-details-value {
  font-size: xx-large;
  font-weight: bold;
  margin-right: 1%;
}

.blood-glucose-time-in-text {
  margin-left: 1%;
  margin-right: 1%;
}

.blood-glucose-card-header {
  padding: 1.5%;
  padding-bottom: 0;
}

.blood-glucose-header-right {
  padding-bottom: 1.5%;
  width: 50%;
}

.blood-glucose-header-left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.blood-glucose-tab-container {
  display: flex;
  color: #1a74d4;
}

.blood-glucose-tab {
  padding-top: 0.5%;
  margin-right: 5%;
  cursor: pointer;
}

.blood-glucose-tab-active {
  color: black !important;
  cursor: unset !important;
  font-weight: bold;
  border-bottom: 2px solid black;
}

.time-range-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.details-tab-switch {
  margin: 2%;
  width: 40%;
}

.detail-row {
  display: flex;
  width: 96%;
  margin-left: 2%;
  border-bottom: 1px solid;
  border-bottom-color: lightgrey;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom-color: black;
}

.detail-column {
  text-align: center;
  padding: 1%;
  margin-left: 2%;
}

.details-icon {
  width: 22px;
  height: 22px;
  left: -12px;
  position: relative;
}

.detail-text-box-container {
  width: 100%;
  background-color: white;
}

.details-text-box {
  width: 90%;
  padding: 1%;
  border: none;
}

.details-text-box:focus-within {
  outline: none;
}

.details-text-icon-container {
  cursor: initial;
  width: 5%;
  text-align: center;
  margin-top: 1%;
}

.detail-button:active {
  opacity: 0.6;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.time-in-range-week {
  display: flex;
  padding: 1%;
}

.blood-glucose-no-data {
  width: 96%;
  margin-left: 2%;
  text-align: center;
}

.detail-sub-item {
  margin-left: 3% !important;
  width: 95% !important;
}

.detail-child-sub-item {
  margin-left: 4% !important;
  width: 94% !important;
}

