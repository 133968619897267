@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-underline {
  text-decoration: none;
  color: inherit;
}

input,
select,
textarea {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  font-size: medium;
}

.fa {
  cursor: pointer;
}

.mt-1 {
  margin-top: 1%;
}

.mt-2 {
  margin-top: 2%;
}

.d-flex {
  display: flex;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.c-pointer {
  cursor: pointer;
}

.ml-1 {
  margin-left: 1% !important;
}

.m-1 {
  margin: 1%;
}

.m-2 {
  margin: 2%;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.w-100 {
  width: 100% !important;
}

.text-bold {
  font-weight: bold;
}

.w-5 {
  width: 5%;
}

.mlr-1 {
  margin-left: 1%;
  margin-right: 1%;
}

.thick-black-divider {
  height: 2px !important;
  background-color: black !important;
}

.w-30 {
  width: 30%;
}

.ml-3 {
  margin-left: 3% !important;
}

.ml-2 {
  margin-left: 2% !important;
}

.no-pointer {
  pointer-events: none;
}
