.steps-header-label {
  width: 50%;
  display: flex;
}

.steps-walked-header {
  display: flex;
  width: 100%;
}

.steps-header-left {
  width: 50%;
  margin-top: 0.8%;
}

.steps-header-right {
  width: 50%;
}

.steps-switch {
  width: 100%;
  margin-top: 0;
}

.steps-header-text {
  font-weight: 600;
  margin-left: 2%;
  margin-right: 2%;
}

.steps-card-header {
  padding: 1.5%;
}

.steps-bar-header {
  display: flex;
  margin-top: 1%;
  font-weight: bold;
  align-items: center;
  border-bottom: 1px solid #e2e3e6;
}

.steps-bar-value {
  font-size: x-large;
  margin-left: 2%;
}

.steps-bar-text {
  margin-left: 3%;
}

.graph-lower-container {
  display: flex;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 0.5%;
  border-radius: 1vh;
  width: 90%;
  margin-left: 5%;
  align-items: center;
  border: 1px solid lightgrey;
  font-weight: bold;
}

.graph-lower-container-text {
  margin-left: 3%;
}
