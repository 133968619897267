.glucose-header-icon {
  margin: 1%;
  height: 28px;
  width: 28px;
}

.blood-glucose-header-label {
  margin-top: 1%;
  margin-left: 2%;
  width: 50%;
  display: flex;
}

.blood-glucose-content-header {
  display: flex;
  justify-content: space-between;
  margin: 0.5%;
  align-items: center;
}

.blood-glucose-content-text {
  font-weight: bold;
  margin-left: 2%;
}

.blood-glucose-bound-button {
  width: max-content;
  padding: 1%;
  margin-right: 2%;
}

.blood-glucose-time-in-container {
  margin-top: 0;
  margin-bottom: 0;
  width: 96%;
  margin-left: 2%;
}

.outer-line-blue-circle {
  border-radius: 8px;
  width: 16px;
  height: 16px;
  border: 2px solid #1a74d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contained-circle {
  border-radius: 4px;
  width: 8px;
  height: 8px;
  background-color: orange;
  border: 1px solid;
}

.dot-details-container {
  display: flex;
  width: 100%;
  padding-bottom: 1%;
}

.dot-details-left-container {
  display: flex;
  width: 30%;
  border-right: 1px solid lightgrey;
  justify-content: center;
}

.dot-details-text {
  margin-left: 3%;
}

.dot-details-right-container {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: baseline;
}

.dot-details-sub-container {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: baseline;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid;
}

.inner-triangle {
  position: relative;
  top: 1px;
  left: -9px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid;
}

.dot-details-value {
  font-size: xx-large;
  font-weight: bold;
  margin-right: 1%;
}

.blood-glucose-time-in-text {
  margin-left: 1%;
  margin-right: 1%;
}

.blood-glucose-card-header {
  padding: 1.5%;
  padding-bottom: 0;
}

.blood-glucose-header-right {
  padding-bottom: 1.5%;
  width: 50%;
}

.blood-glucose-header-left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.blood-glucose-tab-container {
  display: flex;
  color: #1a74d4;
}

.blood-glucose-tab {
  padding-top: 0.5%;
  margin-right: 5%;
  cursor: pointer;
}

.blood-glucose-tab-active {
  color: black !important;
  cursor: unset !important;
  font-weight: bold;
  border-bottom: 2px solid black;
}

.time-range-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.details-tab-switch {
  margin: 2%;
  width: 40%;
}

.detail-row {
  display: flex;
  width: 96%;
  margin-left: 2%;
  border-bottom: 1px solid;
  border-bottom-color: lightgrey;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom-color: black;
}

.detail-column {
  text-align: center;
  padding: 1%;
  margin-left: 2%;
}

.details-icon {
  width: 22px;
  height: 22px;
  left: -12px;
  position: relative;
}

.detail-text-box-container {
  width: 100%;
  background-color: white;
}

.details-text-box {
  width: 90%;
  padding: 1%;
  border: none;
}

.details-text-box:focus-within {
  outline: none;
}

.details-text-icon-container {
  cursor: initial;
  width: 5%;
  text-align: center;
  margin-top: 1%;
}

.detail-button:active {
  opacity: 0.6;
  transform: translateY(4px);
}

.time-in-range-week {
  display: flex;
  padding: 1%;
}

.blood-glucose-no-data {
  width: 96%;
  margin-left: 2%;
  text-align: center;
}

.detail-sub-item {
  margin-left: 3% !important;
  width: 95% !important;
}

.detail-child-sub-item {
  margin-left: 4% !important;
  width: 94% !important;
}
