.log-out {
  margin-top: 5%;
  color: dodgerblue;
  cursor: pointer;
}

.overview-title {
  font-size: x-large;
  font-weight: 600;
  margin-top: 1%;
}

.home-header-container {
  display: flex;
  justify-content: space-between;
}

.overview-button {
  width: 12%;
  padding: 1%;
  border-radius: 1vh;
}

.overview-card {
  background-color: white;
  padding: 1%;
  width: 25%;
  border: 1px solid #e2e3e6;
  border-radius: 1vh;
}

.overview-card-details {
  margin-top: 1%;
  padding-right: 10%;
}

.details-value {
  font-size: xx-large;
  font-weight: bolder;
}

.overview-card-label-container {
  display: flex;
  justify-content: space-between;
}

.overview-card-label-value {
  font-size: x-large;
  font-variant: all-petite-caps;
}

.overview-card-container {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
}

.home-body {
  width: 90%;
}

.overview-card-image-container {
  margin-top: 2%;
  padding: 4%;
  width: 35%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1vh;
}

.overview-card-image-label {
  margin-top: 15%;
  font-size: x-large;
  font-weight: 600;
}

.overview-card-images {
  display: flex;
  justify-content: space-between;
}

.card-hover:hover {
  box-shadow: 2px 2px 7px 0px lightblue;
}
