.searchable-dropdown {
  padding: 0;
  margin-top: 1%;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding: 0.5% !important;
  font-size: medium;
  font-family: inherit;
  font-weight: 500;
  border-radius: 1%;
}

.MuiOutlinedInput-notchedOutline {
  border-color: grey !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: lightblue !important;
}
