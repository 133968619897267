.outline-primary-button {
  padding: 2%;
  width: 40%;
  border: 1px solid dodgerblue;
  color: dodgerblue;
  background-color: inherit;
  border-radius: 1vh;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
}

.outline-primary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.outline-primary-button:active {
  outline: none;
}

.outline-primary-button:focus {
  outline: none;
}
