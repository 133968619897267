.switch-container {
  display: flex;
  margin-top: 2%;
  background-color: #eeeff4;
}

.switch {
  width: 50%;
  padding: 1%;
  text-align: center;
  background-color: #eeeff4;
  cursor: pointer;
}

.switch-active {
  cursor: default;
  border: 1px solid #cbccd2;
  background-color: white;
  box-shadow: 0 0 4px 1px #cbccd2;
}
